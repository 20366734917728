@import "../../config/Variables";

.password-recovery-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #F0F2F5;
  width: 100%;
}

.password-recovery-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.password-recovery-logo-container{
  display: flex;
  flex-direction: column;
  gap: 40px
}

.password-recovery-logo-img {
  height: 70px;
  padding-right: 20px;
}

.password-recovery-form-button {
  width: 100%;
}

#password-recovery-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}

#password-recovery-form_username {
  height: 48px;
}

#password-recovery-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}

.password-recovery-form-button {
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .password-recovery-box {
    flex-direction: column;
    box-shadow: none;
  }

  #password-recovery-form {
    max-width: 100%;
  }
}

.ant-form-item-control-input-content {
  text-align: left;
}

#password-recovery-form {
  flex: 1 0 100%;
  background-color: #F0F2F5;
  min-width: 360px;
  width: 80%;
}

#password-recovery-form p {
  margin-bottom: 30px;
}

#password-recovery-form p.form-title {
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
}

.form-subtitle {
  text-align: center;
}

#password-recovery-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

.custom-file-container {
  width: 100%;
  padding: 0 0;
  margin: 0 0;
}

.custom-file-dragger {
  margin-bottom: 16px;
}

.custom-file-icon {
  display: flex;
  justify-content: center;
  font-size: 32px;
  color: #1890ff;
}

.custom-file-text {
  margin: 8px 0;
  color: #4B5563;
}

.custom-file-help-text {
  color: #9CA3AF;
  font-size: 14px;
}

.custom-file-button {
  width: 100%;
  height: 40px;
  background-color: #1890ff;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-file-button:hover:not(:disabled) {
  background-color: #40a9ff;
}

.custom-file-button:disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

/* Custom File List Styles */
.custom-file-list {
  margin-top: 8px;
}

.custom-file-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 4px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.custom-file-list-item:hover {
  background-color: #f5f5f5;
}

.custom-file-list-item-info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.custom-file-list-item-icon {
  color: #1890ff;
  font-size: 16px;
}

.custom-file-list-item-icon-excel {
  color: #28A745;
}

.custom-file-list-item-icon-pdf {
  color: #FF4B4B;
}

.custom-file-list-item-name {
  color: #000000d9;
  cursor: pointer;
  transition: color 0.3s ease;
}

.custom-file-list-item-name:hover {
  color: #1890ff;
  text-decoration: underline;
}

.custom-file-list-item-size {
  color: #00000073;
  font-size: 12px;
  margin-left: 8px;
}

.custom-file-list-item-actions {
  display: flex;
  gap: 8px;
}

.custom-file-list-item-action {
  color: #00000073;
  cursor: pointer;
  transition: color 0.3s ease;
  background: none;
  border: none;
  padding: 4px;
  display: flex;
  align-items: center;
}

.custom-file-list-item-action:hover {
  color: #ff4d4f;
}

/* Hide default Ant Design upload list */
:global(.ant-upload-list) {
  display: none !important;
}

@import "../../../config/Variables";

.contact-card {
  background: var(--contact-bg-container);
  border-radius: var(--contact-border-radius);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  position: relative;
  width: 100%;
  max-width: 320px;
}

.contact-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 120px;
  background: linear-gradient(135deg, var(--contact-primary-color), var(--contact-primary-hover));
  opacity: 0.1;
  z-index: 0;
}

.contact-content {
  position: relative;
  //z-index: 1;
  z-index: 0;
  padding: 32px 24px 24px;
}

.contact-avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.contact-avatar :global(.ant-avatar) {
  border: 4px solid var(--contact-bg-container);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.contact-info {
  text-align: center;
}

.contact-header {
  margin-bottom: 24px;
}

.contact-header h3 {
  margin: 0;
  font-size: 24px;
  font-weight: 600;
  color: var(--contact-text-primary);
  line-height: 1.4;
  margin-bottom: 12px;
}

.position-badge {
  display: inline-block;
  padding: 6px 12px;
  background: color-mix(in srgb, var(--contact-primary-color) 8%, transparent);
  color: var(--contact-primary-color);
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
}

.contact-actions {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.contact-detail {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  background: var(--contact-bg-elevated);
  border-radius: var(--contact-border-radius-sm);
  text-decoration: none;
  color: var(--contact-text-secondary);
  transition: background-color 0.2s ease, color 0.2s ease;
}

.contact-detail:hover {
  background: color-mix(in srgb, var(--contact-primary-color) 8%, var(--contact-bg-elevated));
}

.contact-detail:hover .icon,
.contact-detail:hover span {
  color: var(--contact-primary-color);
}

.contact-detail span {
  font-size: 15px;
  font-weight: 500;
  transition: color 0.2s ease;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon {
  margin-right: 12px;
  color: var(--contact-primary-color);
  font-size: 18px;
  opacity: 0.9;
  transition: color 0.2s ease;
}

@media (max-width: 640px) {
  .contact-content {
    padding: 24px 20px;
  }

  .contact-header h3 {
    font-size: 22px;
  }

  .contact-detail {
    padding: 10px 14px;
  }

  .contact-detail span {
    font-size: 14px;
  }
}

@import "../../config/Variables";

body {
  font-family: "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue";
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #F0F2F5;
  width: 100%;
}

.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.login-logo-container{
  display: flex;
  flex-direction: column;
  gap: 40px
}

#login-form {
  flex: 1 0 100%;
  background-color: #F0F2F5;
  min-width: 360px;
  width: 80%;
}

#login-form p {
  margin-bottom: 30px;
}

#login-form p.form-title {
  color: #333333;
  font-family: "Josefin Sans", sans-serif;
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 0;
  text-align: center;
}

.form-subtitle {
  text-align: center;
}

#login-form .ant-form-item-label > label.ant-form-item-required::before {
  display: none;
}

.ant-form-item-control-input-content {
  text-align: left;
}

#login-form .ant-input-affix-wrapper {
  padding: 12px 15px;
}

#login-form_username {
  height: 48px;
}

#login-form .ant-btn {
  height: 42px;
  letter-spacing: 1px;
  border-radius: 6px;
}

.login-form-button {
  width: 100%;
}

.illustration-wrapper {
  display: flex;
  align-items: flex-end;
  max-width: 800px;
  min-height: 100%;
  background-color: #fffdf2;
}

.illustration-wrapper img {
  display: block;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .login-box {
    flex-direction: column;
    box-shadow: none;
  }

  .illustration-wrapper {
    max-width: 100%;
    min-height: auto;
  }

  #login-form {
    max-width: 100%;
  }
}

.login-form-forgotten-password {
  text-align: center;
  color: #41b579;
  text-decoration: underline;
  cursor: pointer;
  margin-bottom: 0 !important;
}

.login-form-forgotten-password:hover {
  opacity: 0.85;
}

.login-logo-img {
  height: 70px;
  padding-right: 20px;
}

@import "../../../config/Variables";

.contact-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  gap: 32px;
  padding: 32px;
  max-width: 1400px;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
  justify-items: center;
}

.contact-card {
  width: 100%;
  max-width: 320px;
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 48px 24px;
  background: var(--contact-bg-container);
  border-radius: var(--contact-border-radius);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.06);
  grid-column: 1 / -1;
  min-height: 300px;
}

.empty-state-icon {
  font-size: 64px;
  margin-bottom: 24px;
  color: var(--contact-primary-color);
  opacity: 0.8;
}

.empty-state-title {
  font-size: 24px;
  font-weight: 600;
  color: var(--contact-text-primary);
  margin-bottom: 12px;
}

.empty-state-description {
  font-size: 16px;
  color: var(--contact-text-secondary);
  max-width: 400px;
  line-height: 1.6;
}

/* Skeleton styles */
.contact-card-skeleton {
  width: 100%;
  max-width: 320px;
  height: 100%;
  border-radius: 16px;
  overflow: hidden;
}

.skeleton-content {
  padding: 32px 24px 24px;
}

.skeleton-avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

@media (max-width: 768px) {
  .contact-grid {
    padding: 24px;
    gap: 24px;
  }
}

@media (max-width: 480px) {
  .contact-grid {
    padding: 16px;
    gap: 20px;
  }

  .empty-state {
    padding: 32px 20px;
    min-height: 260px;
  }

  .empty-state-icon {
    font-size: 48px;
    margin-bottom: 20px;
  }

  .empty-state-title {
    font-size: 20px;
  }

  .empty-state-description {
    font-size: 15px;
  }

  .skeleton-content {
    padding: 24px 20px;
  }
}
